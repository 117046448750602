import { Button } from "./ui/button"
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet"
import { Menu } from "lucide-react"

interface NavbarProps {
  activeSection: string
  scrollToSection: (sectionId: string) => void
}

export default function Navbar({ activeSection, scrollToSection }: NavbarProps) {
  const sections = ["about", "education", "experience", "projects"]

  return (
    <nav className="sticky top-0 z-10 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:max-w-[1400px] 2xl:max-w-[1600px]">
        <div className="flex justify-between items-center h-16 md:h-20">
          <div className="flex items-center">
            <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 dark:text-white">Colin Hwang</h1>
          </div>
          <div className="hidden md:flex items-center space-x-4 lg:space-x-6">
            {sections.map((section) => (
              <button
                key={section}
                className={`text-base lg:text-lg ${
                  activeSection === section
                    ? "text-green-600 dark:text-green-400"
                    : "text-gray-600 dark:text-gray-400"
                } hover:text-green-600 dark:hover:text-green-400 transition-colors`}
                onClick={() => scrollToSection(section)}
              >
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </div>
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="outline" size="icon" className="md:hidden">
                <Menu className="h-6 w-6" />
                <span className="sr-only">Toggle navigation menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right">
              <nav className="flex flex-col gap-4">
                {sections.map((section) => (
                  <button
                    key={section}
                    className={`text-lg text-left ${
                      activeSection === section
                        ? "text-green-600 dark:text-green-400"
                        : "text-gray-600 dark:text-gray-400"
                    } hover:text-green-600 dark:hover:text-green-400 transition-colors`}
                    onClick={() => {
                      scrollToSection(section)
                      document.body.click()
                    }}
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </button>
                ))}
              </nav>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </nav>
  )
}