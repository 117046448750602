import { useState, useEffect } from "react"
import Navbar from "../components/Navbar"
import About from "../components/About"
import Education from "../components/Education"
import Experience from "../components/Experience"
import Projects from "../components/Projects"

export default function PortfolioPage() {
  const [activeSection, setActiveSection] = useState("")

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["about", "education", "experience", "projects"]
      let current = ""

      for (const section of sections) {
        const element = document.getElementById(section)
        if (element && element.getBoundingClientRect().top <= 100) {
          current = section
        }
      }

      setActiveSection(current)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId)
    if (element) {
      const navbar = document.querySelector('nav')
      const navbarHeight = navbar ? navbar.offsetHeight : 0
      let offsetPosition

      if (sectionId === 'education') {
        // Custom offset for the Education section
        const educationTitle = element.querySelector('h2')
        if (educationTitle) {
          const titlePosition = educationTitle.getBoundingClientRect().top + window.pageYOffset
          offsetPosition = titlePosition - navbarHeight // Adjust this value as needed
        }
      }

      if (!offsetPosition) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
        offsetPosition = elementPosition - navbarHeight
      }

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })
    }
  }

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Navbar activeSection={activeSection} scrollToSection={scrollToSection} />
      <main className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 xl:max-w-[1400px] 2xl:max-w-[1600px]">
        <About />
        <Education />
        <Experience />
        <Projects />
      </main>
    </div>
  )
}