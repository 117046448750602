interface EducationItemProps {
  degree: string
  institution: string
  period: string
  description: string
}

export default function EducationItem({ degree, institution, period, description }: EducationItemProps) {
  return (
    <div className="border-l-4 border-green-500 pl-4 md:pl-6">
      <div className="mb-2 md:mb-4">
        <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-900 dark:text-white">{degree}</h3>
        <p className="text-base md:text-lg text-green-600 dark:text-green-400">{institution}</p>
        <p className="text-sm md:text-base text-gray-600 dark:text-gray-400">{period}</p>
      </div>
      <p className="text-sm md:text-base lg:text-lg text-gray-600 dark:text-gray-300">{description}</p>
    </div>
  )
}