import { Button } from "./ui/button"
import { ExternalLink, LockIcon } from "lucide-react"

interface ProjectCardProps {
  title: string
  description: string
  details: string[]
  link?: string
}

export default function ProjectCard({ title, description, details, link }: ProjectCardProps) {
  return (
    <div className="border border-gray-200 dark:border-gray-700 rounded-lg h-full flex flex-col">
      <div className="p-4 flex-grow">
        <h3 className="text-lg md:text-xl lg:text-2xl font-semibold text-gray-900 dark:text-white mb-2">{title}</h3>
        <p className="text-sm md:text-base lg:text-lg text-gray-600 dark:text-gray-300 mb-4">
          {description}
        </p>
        <div className="mt-4 mb-4">
          <ul className="list-disc list-inside text-sm md:text-base lg:text-lg text-gray-600 dark:text-gray-300 mb-4">
            {details.map((detail, detailIndex) => (
              <li key={detailIndex}>{detail}</li>
            ))}
          </ul>
          <Button 
            variant="outline" 
            className="w-full text-gray-600 dark:text-gray-400 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-200"
            onClick={() => link && window.open(link, '_blank')}
            disabled={!link}
          >
            {link ? (
              <>
                View Project <ExternalLink className="h-4 w-4 ml-2" />
              </>
            ) : (
              <>
                Project link not available <LockIcon className="h-4 w-4 ml-2" />
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}