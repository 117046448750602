import ProjectCard from "./ProjectCard"

const projects = [
  {
    title: "Esports Manager Chatbot",
    description: "A chatbot capable of constructing teams for VCT under a variety of prompts. Completed as part of the VCT x AWS hackathon.",
    details: [
      "Built with AWS (S3, Lambda, RDS, Bedrock, OpenSearch), Spark, and Python",
      "Processed over 500GB of game data by storing it within an AWS RDS instance",
      "Leveraged AWS Bedrock to orchestrate a multi agent system"
    ],
  },
  {
    title: "Generative Art Recommendation System",
    description: "A novel recommendation system that uses diffusion models and facial features from users to recommend generative art.",
    details: [
      "Developed using React, Typescript, Milvus, Python, and Docker",
      "Extract facial features from a live feed of the user to determine an engagement score",
      "Uses the engagement score to recommend similar/dis-similar artwork"
    ],
  },
  {
    title: "Real-Time Chat Application",
    description: "A messaging platform similar to discord where users can create groupchats/servers and talk in real time.",
    details: [
      "Implemented using PostgreSQL, Spring Boot, JDBC, React, Java, and Firebase",
      "Allows for easy sign in and registration by using firebase auth",
      "Uses websockets to implement real time chatting functionality"
    ],
    link: "https://github.com/CooperCrew/crew-connect"
  },
  {
    title: "Checkers Playing AI",
    description: "A program written in C++ that players checkers against the user. Displayed above average level of play.",
    details: [
      "Built using C++, Minimax Search, Iterative Deepening, and a complex heuristic function",
      "Explores the game space for ideal moves using minimax search with alpha beta pruning",
      "Capable of thinking up to three turns in advance and plays legal moves"
    ],
    link: "https://github.com/colinh09/AI-Checkers"
  },
  {
    title: "Unoptimized C99 Compiler",
    description: "A basic, unoptimized C compiler built to understand compiler design and theory and was able to successfully parse and execute c code.",
    details: [
      "Built using C, Flex, Bison, Assembly, and Abstract Syntax Tree",
      "C code is tokenized by Flex and parsed into an AST by Bison",
      "An AST walk generates intermediate code, which then becomes executable assembly"
    ],
    link: "https://github.com/colinh09/Compilers"
  },
  {
    title: "Social Media for Restuarants",
    description: "An food-based social media application that allows users to rate/view resturants and share their dining experiences with other users.",
    details: [
      "Built using MongoDB, Node.js, React, Google Maps API, ExpressJS, and Firebase",
      "Used the google maps API to fetch resuarant data in NYC",
      "Used mongoDB to store data about users/restuarants and react for the UI"
    ],
    link: "https://github.com/colinh09/BITE"
  },
]

export default function Projects() {
    return (
      <section id="projects" className="mb-20">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-gray-900 dark:text-white">Projects</h2>
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2">
          {projects.map((project, index) => (
            <ProjectCard 
              key={index} 
              {...project} 
            />
          ))}
        </div>
      </section>
    )
  }