import EducationItem from "./EducationItem"

const educationData = [
    {
      degree: "Master of Engineering, Computer Engineering",
      institution: "The Cooper Union",
      period: "2023-2024",
      description: `Primary focus on machine learning, AI, and advanced computer science fields. 
      Coursework includes deep learning, artificial intelligence, compilers, natural language 
      processing, databases, convex optimization, and theoretical computer science. Completed 
      and defended a thesis on using few-shot learning and transfer learning to overcome data 
      scarcity in computer vision tasks.`
    },
  {
    degree: "Bachelor of Engineering, Computer Engineering + Minor, Computer Science",
    institution: "The Cooper Union",
    period: "2020-2024",
    description: `Primary focus on engineering and computer science. Coursework includes data structures
    and algorithms, computer architecture, operating systems, software engineering, calculus, discrete math,
    electronics, and physics. Completed an engineering capstone project that introduced a novel recommendation
    system that uses user engagement determined from user facial features to recommend art using text-to-image 
    generative models.`
  }
]

export default function Education() {
  return (
    <section id="education" className="mb-20">
      <div className="pt-20 md:pt-24">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-6 text-gray-900 dark:text-white">Education</h2>
        <div className="space-y-8 md:space-y-12">
          {educationData.map((item, index) => (
            <EducationItem key={index} {...item} />
          ))}
        </div>
      </div>
    </section>
  )
}