import { Button } from "./ui/button"
import { Github, Linkedin, Mail, Download } from "lucide-react"

export default function About() {
  const downloadResume = () => {
    const link = document.createElement('a');
    link.href = '/Colin_Hwang_Resume.pdf';
    link.download = 'Colin_Hwang_Resume.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <section id="about" className="py-20 md:py-24">
      <div className="md:flex items-center">
        <div className="md:flex-shrink-0 mb-6 md:mb-0 md:mr-8">
          <img
            src="./colin_hwang.jpg"
            alt="Profile picture"
            className="rounded-full w-[200px] h-[200px] md:w-[250px] md:h-[250px] lg:w-[300px] lg:h-[300px]"
          />
        </div>
        <div>
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 dark:text-white mb-2">Colin Hwang</h2>
          <p className="text-xl md:text-2xl lg:text-3xl text-green-600 dark:text-green-400 mb-4">Full Stack, AI/ML, Data Engineering</p>
          <p className="text-base md:text-lg lg:text-xl text-gray-600 dark:text-gray-300 mb-6">
            Hi, I studied computer engineering and computer science at The Cooper Union. I have a strong background in both fullstack development as well as machine learning and AI.
          </p>
          <div className="flex items-center space-x-4">
            <Button 
              variant="outline" 
              size="icon" 
              className="rounded-full w-10 h-10 md:w-12 md:h-12 text-gray-600 dark:text-gray-400 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-200" 
              onClick={() => window.open('https://github.com/colinh09', '_blank')}
            >
              <Github className="h-5 w-5 md:h-6 md:w-6" />
              <span className="sr-only">GitHub</span>
            </Button>
            <Button 
              variant="outline" 
              size="icon" 
              className="rounded-full w-10 h-10 md:w-12 md:h-12 text-gray-600 dark:text-gray-400 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-200" 
              onClick={() => window.open('https://www.linkedin.com/in/colin-hwang-dev/', '_blank')}
            >
              <Linkedin className="h-5 w-5 md:h-6 md:w-6" />
              <span className="sr-only">LinkedIn</span>
            </Button>
            <Button 
              variant="outline" 
              size="icon" 
              className="rounded-full w-10 h-10 md:w-12 md:h-12 text-gray-600 dark:text-gray-400 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-200" 
              onClick={() => window.location.href = 'mailto:hcolin0910@gmail.com'}
            >
              <Mail className="h-5 w-5 md:h-6 md:w-6" />
              <span className="sr-only">Email</span>
            </Button>
            <Button 
              variant="outline" 
              className="rounded-full text-sm md:text-base text-gray-600 dark:text-gray-400 hover:text-green-600 dark:hover:text-green-400 transition-colors duration-200" 
              onClick={downloadResume}
            >
              <Download className="h-5 w-5 md:h-6 md:w-6 mr-2" />
              Download Resume
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}